import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "firstNameField", "middleNameField", "lastNameField", "fullNameField" ]
  
  // connect() {
  //   alert("connected")
  // }
  
  getFullNameFromFields() {
    const firstName = this.firstNameFieldTarget.value.trim()
    const middleName = this.middleNameFieldTarget.value.trim()
    const lastName = this.lastNameFieldTarget.value.trim()
    
    if (middleName.length > 0) {
      return [firstName, middleName, lastName].join(" ").trim()
    }
    
    return [firstName, lastName].join(" ").trim()
  }
  
  update() {
    const newFullName = this.getFullNameFromFields()
    this.fullNameFieldTarget.value = newFullName
  }
}